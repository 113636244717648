import {useContext, useEffect, useState} from 'react';
import {Button, Modal, Textarea} from 'flowbite-react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import {AppContext, IAppContextState} from '../../../App';

import {INote} from '../interface/note.interface';
import {IAyat} from '../interface/surah.interface';

import {StorageService} from '../../../shared/service/storageService';
import {NoteService} from "../../bookmark/service/NoteService";

interface IAddNoteModalProps {
    state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    data: IAyat;
    saveNote: (note_text: any, ayat: any, ayat_code: any) => void;
    updateNote: (id:number,payload:any) => void;
}

function AddNoteModal({state, data, saveNote,updateNote}: IAddNoteModalProps) {
    const {noteState,getAllNotes,userState} = useContext<IAppContextState | any>(AppContext);
    const [openModal, setOpenModal] = state;
    const [notes, setNotes] = noteState;
    const [noteTextInput, setNoteTextInput] = useState('');
    const {t} = useTranslation();
    const [user] = userState;

    const addToNote = async () => {
        const note = notes?.find((note: INote) => note.ayat_code === data.ayat_code);
        console.log("(AddNoteModal.tsx:33)  note =>", note);
        if (note) {
            const payload={
                text:noteTextInput,
                ayat:data.id,
                ayat_code:data.ayat_code,
                user:user.id
            }
            updateNote(note.id,payload)
        } else {
            saveNote(noteTextInput, data.id, data.ayat_code);

        }
    };

    const findNote = (ayatCode: string) => {
        const note =notes?.find((note: INote) => note.ayat_code === ayatCode);
        setNoteTextInput(note?.text ?? '');
    };

    useEffect(() => {
        if (data?.ayat_code) {
            findNote(data.ayat_code);
        }
    }, [data]);

    return (
        <Modal show={openModal} size='md' onClose={() => setOpenModal(false)} popup>
            <Modal.Header>
                <h4 className='p-3'>{t('note.modal.title')}</h4>
            </Modal.Header>
            <Modal.Body>
                <div className='text-center pt-2'>
                    <Textarea
                        value={noteTextInput}
                        onChange={(e) => setNoteTextInput(e.target.value)}
                        className='text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-yellow-400 focus:border-yellow-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-500 dark:focus:border-yellow-500'
                        placeholder={t('placeholder.addNote')}
                    />
                    <div className='flex justify-center gap-3 mt-3'>
                        <Button disabled={noteTextInput === ''} color='success' onClick={() => addToNote()}>
                            {t('action.save')}
                        </Button>
                        <Button color='gray' onClick={() => setOpenModal(false)}>
                            {t('action.no')}
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddNoteModal;
