import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import AuthFormContainer from "./AuthFormContainer";

import { EFormErrorType } from "../../../shared/enum/form.enum";

import { PASSWORD_REGEX } from "../../../shared/constant/regex.constant";

type TSetNewPasswordForm = {
  newPassword: string;
  confirmPassword: string;
};

function SetNewPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<TSetNewPasswordForm>({ mode: "onChange" });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isShowNewPassword, setIsShowNewPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const submit = (data: TSetNewPasswordForm) => {};

  return (
    <AuthFormContainer>
      <form onSubmit={handleSubmit(submit)}>
        <h4 className="text-center text-2xl font-bold">{t("auth.title.set_new_password")}</h4>
        <div className="mb-5 mt-5">
          <label htmlFor="register_password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            {t("auth.label.new_password")}
          </label>
          <div className="relative">
            <input
              type={isShowNewPassword ? "text" : "password"}
              id="register_password"
              placeholder={t("auth.placeholder.new_password")}
              className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white ${
                errors?.newPassword?.type === EFormErrorType.REQUIRED ||
                errors?.newPassword?.type === EFormErrorType.PATTERN
                  ? "dark:bg-red-700 dark:border-red-600 border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500 focus:ring-red-500 focus:border-red-500"
                  : "dark:bg-gray-700 dark:border-gray-600 border-gray-300 dark:focus:ring-yellow-400 dark:focus:border-yellow-400 focus:ring-yellow-400 focus:border-yellow-400"
              }`}
              {...register("newPassword", {
                required: true,
                pattern: PASSWORD_REGEX,
              })}
            />
            <div
              onClick={() => setIsShowNewPassword(!isShowNewPassword)}
              className="absolute top-2/4 right-[6px] -translate-y-2/4 cursor-pointer"
            >
              {isShowNewPassword ? (
                <i className="ri-eye-line text-xl"></i>
              ) : (
                <i className="ri-eye-off-line text-xl"></i>
              )}
            </div>
          </div>
          {errors?.newPassword?.type === EFormErrorType.REQUIRED ? (
            <span className="text-red-600 text-xs">{t("auth.validation.password.required")}</span>
          ) : (
            <></>
          )}
          {errors?.newPassword?.type === EFormErrorType.PATTERN ? (
            <span className="text-red-600 text-xs">{t("auth.validation.password.pattern")}</span>
          ) : (
            <></>
          )}
        </div>
        <div className="mb-5">
          <label
            htmlFor="set_new_confirm_password"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {t("auth.label.confirm_password")}
          </label>
          <div className="relative">
            <input
              type={isShowConfirmPassword ? "text" : "password"}
              id="set_new_confirm_password"
              placeholder={t("auth.placeholder.confirm_password")}
              className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white ${
                errors?.confirmPassword?.type === EFormErrorType.REQUIRED ||
                errors?.confirmPassword?.type === EFormErrorType.PATTERN
                  ? "dark:bg-red-700 dark:border-red-600 border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500 focus:ring-red-500 focus:border-red-500"
                  : "dark:bg-gray-700 dark:border-gray-600 border-gray-300 dark:focus:ring-yellow-400 dark:focus:border-yellow-400 focus:ring-yellow-400 focus:border-yellow-400"
              }`}
              {...register("confirmPassword", {
                required: true,
                pattern: PASSWORD_REGEX,
              })}
            />
            <div
              onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
              className="absolute top-2/4 right-[6px] -translate-y-2/4 cursor-pointer"
            >
              {isShowConfirmPassword ? (
                <i className="ri-eye-line text-xl"></i>
              ) : (
                <i className="ri-eye-off-line text-xl"></i>
              )}
            </div>
          </div>
          {errors?.confirmPassword?.type === EFormErrorType.REQUIRED ? (
            <span className="text-red-600 text-xs">{t("auth.validation.confirm_password.required")}</span>
          ) : (
            <></>
          )}
          {errors?.confirmPassword?.type === EFormErrorType.PATTERN ? (
            <span className="text-red-600 text-xs">{t("auth.validation.confirm_password.pattern")}</span>
          ) : (
            <></>
          )}
        </div>
        <button
          disabled={!isValid || isLoading}
          type="submit"
          className="cursor-pointer text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
        >
          {t("auth.action.submit")}
        </button>
        <button
          onClick={() => navigate("/auth/login")}
          type="button"
          className="mt-3 border text-yellow-400 border-yellow-400 hover:bg-yellow-400 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
        >
          {t("auth.action.back_to_login")}
        </button>
      </form>
    </AuthFormContainer>
  );
}

export default SetNewPassword;
