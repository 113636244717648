import { StorageService } from "./storageService";

export class UtilService {
  private storageService = new StorageService();

  getFileExtension(fileType: string) {
    const slices = fileType.split(".");
    return slices?.[slices.length - 1]?.toLowerCase();
  }

  previewFromFile = (file: File) => {
    return URL.createObjectURL(file);
  };

  buildAuthorizationHeader() {
    const token = this.storageService.getAccessToken();
    return {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
  }
}
