import React from 'react';

const DonationCancel = () => {
    return (
        <div className="donation-cancel">
            <h1>Donation Cancelled</h1>
            <p>It seems like the donation process was not completed. You can try again anytime.</p>
            <br/>
            <button className='bg-yellow-400 block text-white rounded-full py-2 px-3' onClick={() => window.location.href = '/donate'}>Try Again</button>
        </div>
    );
};

export default DonationCancel;
