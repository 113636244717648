import React, { useState } from "react";

const CurrencyInputWithDropdown: React.FC = () => {
    const [selectedCurrency, setSelectedCurrency] = useState<string>("EUR");
    const [amount, setAmount] = useState<string>("10.00");
    const [error, setError] = useState<string>("");

    const currencyOptions = [
        { code: "EUR", symbol: "€", value: "eur", amount: 10 },
        { code: "USD", symbol: "$", value: "usd", amount: 10 },
        { code: "GBP", symbol: "£", value: "gbp", amount: 10 },
        { code: "JPY", symbol: "¥", value: "jpy", amount: 10 },
    ];

    const formatCurrency = (value: string): string => {
        if (!value) return "";
        const numberValue = parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
        return numberValue.toLocaleString("en-US");
        // return numberValue.toLocaleString("en-US", {
        //     style: "currency",
        //     currency: selectedCurrency,
        // });
    };

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        // Validate input to allow only numbers and one decimal point
        // if (/^\d*\.?\d*$/.test(inputValue)) {
        //     setAmount(formatCurrency(inputValue));
        // }
        let numericValue = inputValue.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except `.`

        // Restrict to only one dot and limit to two decimal places
        numericValue = numericValue.replace(/^(\d*\.?\d{0,2}).*/, "$1");

        setAmount(numericValue);
        // setAmount(formatCurrency(numericValue));
    };

    const handleCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCurrency(event.target.value);
        var amount = "10.00"
        const numberValue = parseFloat(amount.replace(/[^0-9.-]+/g, "")) || 0;
        var reset_value = numberValue.toLocaleString("en-US")
        // var reset_value = numberValue.toLocaleString("en-US", {
        //     style: "currency",
        //     currency: event.target.value,
        // })
        setAmount(reset_value);
    };

    const validateInput = () => {
        const numericValue = parseFloat(amount.replace(/[^0-9.]/g, "")); // Remove non-numeric characters
        if (!amount) {
            setError("Amount is required.");
            return false;
        }
        if (isNaN(numericValue) || numericValue <= 0) {
            setError("Please enter a valid positive amount.");
            return false;
        }
        return true;
    };

    const handleDonate = () => {
        if (validateInput()) {
            // Create PaymentIntent as soon as the page loads
            fetch('https://api.quranrt.org/api/v1/api/create-payment-url/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ amount: amount, currency: selectedCurrency }), // amount in cents
            })
                .then((res) => res.json())
                .then((data) => {
                    var url = data.payment_url;
                    window.location.href = url;
                });
        }

    };

    return (
        <div className="mt-5 p-4 border rounded shadow-md max-w-sm mx-auto">
            <label htmlFor="currency" className="block text-sm font-medium mb-2">
                Select Currency
            </label>
            <select
                id="currency"
                value={selectedCurrency}
                onChange={handleCurrencyChange}
                className="border rounded px-3 py-2 mb-4 w-full"
            >
                {currencyOptions.map((currency) => (
                    <option key={currency.code} value={currency.value}>
                        {currency.code} ({currency.symbol})
                    </option>
                ))}
            </select>

            <label htmlFor="amount" className="block text-sm font-medium mb-2">
                Enter Amount
            </label>
            
            <input
                id="amount"
                type="text"
                value={amount}
                onChange={handleAmountChange}
                className="border rounded px-4 py-2 w-full"
                placeholder={`Enter amount in ${selectedCurrency}`}
            />
            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
            <div className='text-center mt-5'>
                <a
                    // href='mailto:quranrt.org@gmail.com'
                    onClick={handleDonate}
                    className='px-16 py-3 inline-block bg-yellow-300 cursor-pointer rounded-full text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 text-cyan-600 border-b-2 border-cyan-600 dark:text-gray-700 dark:border-yellow-400'>
                    Donate Now
                </a>
            </div>
        </div>

    );
};

export default CurrencyInputWithDropdown;
