import {useEffect, useState} from "react";
import {Button, Modal} from "flowbite-react";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next"
import {VERSE_REGEX} from "../../../shared/constant/regex.constant";
import {IAyat} from "../interface/surah.interface";
import {EFormErrorType} from "../../../shared/enum/form.enum";
import {bismillahText, ignoreSurah} from "../../../shared/constant";

interface IAdvanceCopyModalProps {
    state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    data: any;
    closeAction: () => void;
}

type TAdvanceCopyForm = {
    from: string;
    to: string;
    isWithTranslation: boolean;
};

function AdvanceCopyModal({state, data, closeAction}: IAdvanceCopyModalProps) {
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors, isValid},
    } = useForm<TAdvanceCopyForm>({mode: "onChange"});

    const {surahDetails,surahId}=data
    const ayahs = surahDetails?.ayah;
    const from = ayahs?.[0]?.ayat_code;
    const to = ayahs?.[ayahs?.length - 1]?.ayat_code;

    const [openAdvanceCopyModal] = state;
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
        toast.success(t("message.copy.success"));
    };

    const submit = (formData: TAdvanceCopyForm) => {

        const modifiedFormDataFrom=Number((formData?.from)?.split(':')[1])
        const modifiedFormDataTo=Number(formData?.to.split(':')[1])
        const modifiedAyahFrom=Number(from.split(':')[1])
        const modifiedAyahTo=Number(to.split(':')[1])

        if (
            modifiedFormDataFrom >= modifiedAyahFrom &&
            modifiedFormDataFrom <=modifiedAyahTo &&
            modifiedFormDataTo >= modifiedAyahFrom &&
            modifiedFormDataTo <= modifiedAyahTo &&
            modifiedFormDataFrom <= modifiedFormDataTo
        ) {
            setIsLoading(true);
            const filterVerse = ayahs?.filter(
                (ayah: IAyat) =>  Number(ayah?.ayat_code.split(':')[1]) >= modifiedFormDataFrom && Number(ayah?.ayat_code.split(':')[1]) <= modifiedFormDataTo
            );

            let resultText = "";

            if (formData.from === ayahs?.[0]?.ayat_code && !ignoreSurah.get(Number(surahId))) {
                resultText = bismillahText + '\n' + resultText;
            }
            filterVerse?.forEach((item: IAyat, index: number) => {
                if (formData?.isWithTranslation) {
                    resultText += `(${index + 1}) ${item.ayat}\n`;
                    resultText += `${item.translation?.ayat_translation}\n\n`;
                } else {
                    resultText += `(${index + 1}) ${item.ayat}\n`;
                }
            });

            copyText(resultText);
            setIsLoading(false);
            closeAction();
        } else {
            toast.error(t("modal.advance_copy.validation.invalid"));
        }
    };

    useEffect(() => {
        setValue("from", from);
        setValue("to", to);
    }, [surahDetails]);

    return (
        <Modal show={openAdvanceCopyModal} size="lg" onClose={() => closeAction()} popup>
            <Modal.Header>
                <h4 className="p-3 m-0">{t("modal.advance_copy.title")}</h4>
            </Modal.Header>
            <hr/>
            <Modal.Body>
                <div className="pt-6">
                    <h4 className="text-xl mb-4">{t("modal.advance_copy.body")}</h4>
                    {surahDetails?.surah?.title_translation ? (
                        <p className="text-base">
                            {surahDetails?.surah?.title_translation} {surahDetails?.surah?.total_ayat ?? 0} {t("modal.advance_copy.ayah")}
                        </p>
                    ) : (
                        <></>
                    )}

                    <form onSubmit={handleSubmit(submit)}>
                        <div className="my-5">
                            <label
                                htmlFor="advance_copy_from_verse"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                {t("modal.advance_copy.label.from_verse")}:
                            </label>
                            <input
                                type="text"
                                id="advance_copy_from_verse"
                                className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white`}
                                placeholder={t("modal.advance_copy.placeholder.from_verse")}
                                {...register("from", {required: true, pattern: VERSE_REGEX})}
                            />
                            {errors?.from?.type === EFormErrorType.REQUIRED ? (
                                <span
                                    className="text-red-600 text-xs">{t("modal.advance_copy.validation.from_required")}</span>
                            ) : (
                                <></>
                            )}
                            {errors?.from?.type === EFormErrorType.PATTERN ? (
                                <span
                                    className="text-red-600 text-xs">{t("modal.advance_copy.validation.verse_input")}</span>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="my-5">
                            <label
                                htmlFor="advance_copy_to_verse"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                {t("modal.advance_copy.label.to_verse")}:
                            </label>
                            <input
                                type="text"
                                id="advance_copy_to_verse"
                                className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white`}
                                placeholder={t("modal.advance_copy.placeholder.to_verse")}
                                {...register("to", {required: true, pattern: VERSE_REGEX})}
                            />
                            {errors?.to?.type === EFormErrorType.REQUIRED ? (
                                <span
                                    className="text-red-600 text-xs">{t("modal.advance_copy.validation.to_required")}</span>
                            ) : (
                                <></>
                            )}
                            {errors?.to?.type === EFormErrorType.PATTERN ? (
                                <span
                                    className="text-red-600 text-xs">{t("modal.advance_copy.validation.verse_input")}</span>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="flex items-center">
                            <input
                                id="advance_modal_translation_checkbox"
                                type="checkbox"
                                {...register("isWithTranslation")}
                                className="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-400 dark:focus:ring-yellow-400 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                                htmlFor="advance_modal_translation_checkbox"
                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                {t("modal.advance_copy.label.with_translation")}
                            </label>
                        </div>
                        <div className="mt-4">
                            <div className="flex">
                                <Button
                                    disabled={!isValid || isLoading}
                                    type="submit"
                                    className="cursor-pointer text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg"
                                >
                                    {t("action.copy")}
                                </Button>
                                <Button type="button" color="yellow" className="ml-2" onClick={() => closeAction()}>
                                    {t("action.close")}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AdvanceCopyModal;
