import axios from "axios";
import {UtilService} from "../../../shared/service/utilService";
import {IHttpResponse} from "../../../shared/interface/http-response.interface";
import {BASE_URL} from "../../../environments/environment";
import {INote} from "../../home/interface/note.interface";

export class NoteService {
    public utilService = new UtilService();

    async getNotes(): Promise<IHttpResponse<INote[]>> {
        const {data} = await axios.get(`${BASE_URL}/utility/note/`, this.utilService.buildAuthorizationHeader());
        return data;
    }


    async createNote(payload: any): Promise<any> {
        const {data} = await axios.post(
            `${BASE_URL}/utility/note/`,
            payload,
            this.utilService.buildAuthorizationHeader()
        );
        return data;
    }

    async updateNote(id: any, payload: any): Promise<any> {
        const {data} = await axios.put(
            `${BASE_URL}/utility/note/${id}`,
            payload,
            this.utilService.buildAuthorizationHeader()
        );
        return data;
    }

    async removeNote(id: number): Promise<INote> {
        const {data} = await axios.delete(
            `${BASE_URL}/utility/note/${id}`,
            this.utilService.buildAuthorizationHeader()
        );
        return data;
    }
}
