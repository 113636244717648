import {useContext, useEffect, useRef, useState} from "react";
import {useParams} from "react-router";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {Dropdown} from "flowbite-react";
import {AppContext, IAppContextState} from "../../../App";
import {IAyat, ISurahDetails} from "../interface/surah.interface";
import {SurahService} from "../service/SurahService";
import {StorageService} from "../../../shared/service/storageService";
import {BookmarkService} from "../../bookmark/service/BookmarkService";
import Header from "../../../shared/components/Header";
import SurahDetailsSkeleton from "../skeleton/SurahDetailsSkeleton";
import AddToBookmarkModal from "../../bookmark/components/AddToBookmarkModal";
import {flowbiteCustomDropdownThemeConfig} from "../../../shared/config/flowbiteCustomThemeConfig";
import AddNoteModal from "./AddNoteModal";
import {INote} from "../interface/note.interface";
import CopyOrHighlightSelectModal from "../../../shared/components/CopyOrHighlightSelectModal";
import AdvanceCopyModal from "./AdvanceCopyModal";
import CreateBookmarkCategoryModal from "../../bookmark/components/CreateBookmarkCategoryModal";
import {IHighlight} from "../../highlight/interface/highlight.interface";
import {bismillahText, ignoreSurah} from "../../../shared/constant";
import {NoteService} from "../../bookmark/service/NoteService";

function AyatDetails() {
    const bookmarkService = new BookmarkService();
    const noteService = new NoteService();
    const {t} = useTranslation();
    const surahDetailsDefaultValue = {
        total_counts: 0,
        surah: null,
        ayah: [],
    };
    const {
        langCodeState,
        bookmarkState,
        getBookmarkCollection,
        highlightState,
        addOrRemoveHighlight,
        isReadingModeState,
        noteState,
        userState,
        getAllBookmarks,
        getAllNotes
    } = useContext<IAppContextState | any>(AppContext);

    const {id} = useParams();

    const [bookmarks, setBookmarks] = bookmarkState
    const [user] = userState;
    const [langCode] = langCodeState;
    const [highlights] = highlightState;
    const [isReadingMode] = isReadingModeState;
    const [notes, setNotes] = noteState;
    const highlighWordMapRef = useRef(new Map());
    const [isWordMapReady, setIsWordMapReady] = useState(false);

    useEffect(() => {
        highlighWordMapRef.current.clear()
        if (highlights && highlights.length > 0) {
            highlights.forEach((highlight: IHighlight) => {
                const key = `${highlight?.ayat?.surah}_${highlight?.ayat?.id}_${highlight.word}`;
                highlighWordMapRef.current.set(key, highlight.word);
            });
        }
        setIsWordMapReady(true);
    }, [highlights.length]);
    const [loading, setLoading] = useState(false);
    const [markedBookmarkAyat, setMarkedBookmarkAyat] = useState<IAyat | null>(null);
    const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);

    const [openAddBookmarkModal, setOpenAddBookmarkModal] = useState(false);
    const [openAddNoteModal, setOpenAddNoteModal] = useState(false);
    const [openCopyOrHighlightModal, setCopyOrHighlightModal] = useState(false);
    const [openAdvanceCopyModal, setOpenAdvanceCopyModal] = useState(false);

    const [addNoteModalData, setAddNoteModalData] = useState<any>(null);
    const [advanceCopyModalData, setAdvanceCopyModalData] = useState<any>({
        surahDetails: {},
        surahId: null
    });
    const [copyOrHighlightModalData, setCopyOrHighlightModalData] = useState<any>(null);
    const [surahDetails, setSurahDetails] = useState<ISurahDetails>(surahDetailsDefaultValue);

    const surahService = new SurahService();

    const copyText = (text: string, key?: number) => {
        if (key === 0 && id && !ignoreSurah.get(Number(id))) {
            text = bismillahText + '\n' + text;
        }
        navigator.clipboard.writeText(text);
        toast.success(t("message.copy.success"));
    };

    const handleOpenAddNoteModal = (ayat: IAyat) => {
        setAddNoteModalData(ayat);
        setOpenAddNoteModal(true);
    };

    const handleOpenAdvanceModal = (data?: any, surahId?: any) => {
        setOpenAdvanceCopyModal(true);
        setAdvanceCopyModalData({surahDetails: data, surahId} ?? null);
    };

    const closeAdvanceModal = () => {
        setOpenAdvanceCopyModal(false);
        setAdvanceCopyModalData({
            surahDetails: {},
            surahId: null
        });
    };

    const handleOpenCopyOrHighlightSelectModal = (word: string, ayatId: number, idx: number) => {

        setCopyOrHighlightModalData({word, ayatId, idx});
        setCopyOrHighlightModal(true);
    };

    const closeCopyOrHighlightSelectModal = () => {
        setCopyOrHighlightModalData(null);
        setCopyOrHighlightModal(false);
    };

    const handleOpenAddBookmarkModal = (ayat: IAyat) => {
        // if (bookmarkedAyatCodes.includes(ayat.ayat_code)) {
        //   removeAyat(ayat.ayat_code);
        // } else {
        // }
        if(!user){
            toast.error(t("auth.title.login"));
            return
        }

        setMarkedBookmarkAyat(ayat);
        setOpenAddBookmarkModal(true);
    };

    const removeAyat = (ayatCode: string) => {
        try {
            toast.success(`${t("bookmark.messages.ayat.delete")}`);
        } catch (error: any) {
            toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
        }
    };

    const addToBookmark = async (collectionId: number) => {
        try {
            if (markedBookmarkAyat?.id) {
                const res = await bookmarkService.createBookmark({
                    collection: collectionId,
                    user: user.id,
                    ayat: markedBookmarkAyat.id,
                });
                // setMarkedBookmarkAyat(null);
                // setOpenAddBookmarkModal(false);
                // setOpenAddCategoryModal(false);
                await getAllBookmarks()
                // toast.success(`${t("bookmark.messages.ayat.add")}`);
            }
        } catch (error: any) {
            toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
        }
    };
    const addToNote = async (note_text:any,ayat:any,ayat_code:any) => {
        try {

                const res = await noteService.createNote({
                   text:note_text,
                    user: user.id,
                    ayat:ayat,
                    ayat_code,

                });
                console.log("(AyatDetails.tsx:173)  res =>", res);
                setOpenAddNoteModal(false);
                await getAllNotes()
                toast.success(`${t("note.messages.addNote")}`);

        } catch (error: any) {
            toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
        }
    };

    const updateNote = async (id:any,payload:any) => {
        try {

            await noteService.updateNote(id,payload)
            await getAllNotes()
            setOpenAddNoteModal(false);
            toast.success(`${t('note.messages.updateNote')}`);

        } catch (error: any) {
            toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
        }
    };

    //TODO need refactoring. Duplicate code
    const createCategory = async (title: string) => {
        try {
            const collection = await bookmarkService.createBookmarkCollection({title});
            if (collection && collection.id) {
                await addToBookmark(collection.id)
            }
            await getBookmarkCollection()
            await getAllBookmarks()
            toast.success(`${t("bookmark.messages.collection.add")}`);
        } catch (error: any) {
            toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
        }
    };
    const deleteCategory = async (collectionId: number) => {
        try {
            if (collectionId) {
                await bookmarkService.removeBookmarkCollection(collectionId);
                await getBookmarkCollection()
                toast.success(`${t("bookmark.messages.collection.delete")}`);
            }
        } catch (error: any) {
            toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
        }
    };

    const getSurahDetails = async (id: number) => {
        try {
            setLoading(true);
            const surahDetailsResponse = await surahService.getOneSurah(id, {lang_code: langCode});
            setSurahDetails(surahDetailsResponse?.data ?? []);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };



    useEffect( () => {
        getAllBookmarks()
        getAllNotes()

    }, []);


    useEffect(() => {
        if (id) {
            getSurahDetails(Number(id));
        }
        return () => {
            setSurahDetails(surahDetailsDefaultValue);
            setMarkedBookmarkAyat(null);
            setAddNoteModalData(null);
        };
    }, [id, langCode]);

    const copyDropdownLabel: HTMLElement | any = (
        <button className="group hover:bg-gold rounded-full w-[34px] h-[34px] shadow dark:shadow-yellow-400">
            <i className="ri-file-copy-fill group-hover:text-black text-gray-500 text-lg dark:text-gray-400"></i>
        </button>
    );

    const moreDropdownLabel: HTMLElement | any = (
        <button className="group hover:bg-gold rounded-full w-[34px] h-[34px] shadow dark:shadow-yellow-400">
            <i className="ri-more-fill group-hover:text-black text-gray-500 text-lg  dark:text-gray-400"></i>
        </button>
    );

    return (
        <>
            <AdvanceCopyModal
                state={[openAdvanceCopyModal, setOpenAdvanceCopyModal]}
                data={advanceCopyModalData}
                closeAction={closeAdvanceModal}
            />
            <CopyOrHighlightSelectModal
                closeAction={closeCopyOrHighlightSelectModal}
                state={[openCopyOrHighlightModal, setCopyOrHighlightModal]}
                data={copyOrHighlightModalData}
            />
            <AddNoteModal
                saveNote={(note_text:any,ayat:any,ayat_code:any) => {
                addToNote(note_text,ayat,ayat_code);
            }}       updateNote={(id:number,payload:any) => {
                updateNote(id,payload);
            }}
                              data={addNoteModalData} state={[openAddNoteModal, setOpenAddNoteModal]}/>
            <CreateBookmarkCategoryModal
                state={[openAddCategoryModal, setOpenAddCategoryModal]}
                clickAction={(title: string) => createCategory(title)}
            />
            <AddToBookmarkModal
                markedBookmarkAyat={markedBookmarkAyat}
                clickAction={(collectionId: number) => {
                    addToBookmark(collectionId);
                }}
                deleteAction={(collectionId: number) => {
                    deleteCategory(collectionId);
                }}
                state={[openAddBookmarkModal, setOpenAddBookmarkModal, setOpenAddCategoryModal]}
            />
            <Header>
                {loading ? (
                    <SurahDetailsSkeleton/>
                ) : (
                    <div className="mx-auto px-3 md:max-w-[600] xl:max-w-[1100px] 2xl:max-w-[1400px]">
                        <div className="text-center pt-12 pb-16">
                            <p className="text-[3rem] font-arabic font-bold dark:text-gray-200">
                                {surahDetails?.surah?.surah_name_arabic}
                            </p>
                            <p className="text-[1.15rem] dark:text-gray-300">{surahDetails?.surah?.title_translation}</p>
                            {Number(id) !== 1 && Number(id) !== 9 && (
                                <p className="text-[2rem] dark:text-gray-300 font-arabic mt-3">
                                    بِسۡمِ اللّٰہِ الرَّحۡمٰنِ الرَّحِیۡمِ
                                </p>
                            )}
                        </div>
                        {surahDetails?.ayah?.map((item: IAyat, key: number) =>
                                isReadingMode ? (
                                    <div id={`ayat_${surahDetails.surah?.id}_${item.ayat_code}`} key={`ayat_list_${key}`}>
                                        <p className="font-arabic flex justify-center items-center text-[1.5rem] mb-8">
                                            <div className="text-center flex flex-wrap flex-row-reverse">
                                                {item.ayat.split(" ").map((word, index) => (
                                                    <span
                                                        key={`arabic_word_${item.id}_${index}`}
                                                        onClick={() => addOrRemoveHighlight(word, item.id)}
                                                        className={`${
                                                            highlights.includes(word)
                                                                ? "bg-yellow-400 text-white font-bold hover:text-white"
                                                                : "hover:text-yellow-400"
                                                        } dark:text-gray-300 mx-2 cursor-pointer text-[2rem] arabic-bengali`}
                                                    >
                          {word}
                        </span>
                                                ))}
                                            </div>
                                        </p>
                                    </div>
                                ) : (
                                    <div
                                        id={`ayat_${key + 1}`}
                                        key={`ayat_list_${key}`}
                                        className="border-b border-gray-300 border-dashed dark:border-gray-500 last:border-0"
                                    >
                                        <div className="lg:flex justify-between mb-8 mt-6">
                                            <ul className="w-[5%] flex lg:block mb-4 lg:mb-0">
                                                <li className="lg:mb-3 me-2 mt-1">
                        <span
                            className="dark:text-gray-400 dark:bg-gray-600 px-2 py-1 rounded-lg dark:font-bold text-xs text-nowrap">
                          <span className="dark:font-bold">{item.ayat_code}</span>
                        </span>
                                                </li>
                                                <li className="lg:mb-3 me-2">
                                                    <button
                                                        className="group hover:bg-gold dark:hover:bg-yellow-400 rounded-full w-[34px] h-[34px] shadow dark:shadow-yellow-400">
                                                        <i className="ri-play-fill group-hover:text-black text-gray-500 text-lg dark:text-gray-400"></i>
                                                    </button>
                                                </li>
                                                <li className="lg:mb-3 me-2">
                                                    <button
                                                        onClick={() => handleOpenAddBookmarkModal(item)}
                                                        className={`${
                                                            bookmarks.find((bookmark: any) => bookmark.ayat.ayat_code === item.ayat_code)
                                                                ? "bg-yellow-400 hover:bg-yellow-500"
                                                                :
                                                        " dark:hover:bg-yellow-400 dark:shadow-yellow-400"
                                                        } group rounded-full w-[34px] h-[34px] shadow`}
                                                    >
                                                        <i
                                                            className={`${
                                                                bookmarks.find((bookmark: any) => bookmark.ayat.ayat_code === item.ayat_code)
                                                                  ? "text-white "
                                                                  :
                                                                "text-gray-500 dark:text-gray-400 group-hover:text-black"
                                                            } ri-bookmark-fill text-lg`}
                                                        ></i>
                                                    </button>
                                                </li>
                                                <li className="lg:mb-3 me-2">
                                                    <button
                                                        onClick={() => handleOpenAddNoteModal(item)}
                                                        className={`${
                                                            notes.find((note: INote) => note.ayat_code === item.ayat_code)
                                                                ? "bg-yellow-400 hover:bg-yellow-500"
                                                                : "hover:bg-gold dark:hover:bg-yellow-400 dark:shadow-yellow-400"
                                                        } group rounded-full w-[34px] h-[34px] shadow`}
                                                    >
                                                        <i
                                                            className={`${
                                                                notes.find((note: INote) => note.ayat_code === item.ayat_code)
                                                                    ? "text-white"
                                                                    : "text-gray-500 dark:text-gray-400 group-hover:text-black"
                                                            } ri-sticky-note-add-fill text-lg`}
                                                        ></i>
                                                    </button>
                                                </li>
                                                <li className="lg:mb-3 me-2">
                                                    <Dropdown
                                                        label=""
                                                        renderTrigger={() => copyDropdownLabel}
                                                        theme={flowbiteCustomDropdownThemeConfig}
                                                    >
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                copyText(`
${item.ayat}
${item.translation.ayat_translation}
`)
                                                            }
                                                        >
                                                            Copy All
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => copyText(item.ayat, key)}>Copy
                                                            Arabic</Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => copyText(item.translation.ayat_translation)}>
                                                            Copy Translation
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => handleOpenAdvanceModal(surahDetails, id)}>
                                                            Advance Copy
                                                        </Dropdown.Item>
                                                    </Dropdown>
                                                </li>
                                            </ul>
                                            <div className="w-[95%]">
                                                <div className="text-end flex flex-wrap flex-row-reverse">
                                                    {isWordMapReady && item.ayat.split(" ").map((word, index) => {
                                                        const highlightWord = highlighWordMapRef.current.get(`${surahDetails.surah?.id}_${item.id}_${word}`);
                                                        const className = `${highlightWord === word ? "bg-yellow-400 text-white font-bold" : "hover:text-yellow-400"} font-arabic dark:text-gray-300 mx-2 cursor-pointer text-[2rem]`;

                                                        return (
                                                            <span
                                                                key={`arabic_word_${item.id}_${index}`}
                                                                onClick={() => {
                                                                    handleOpenCopyOrHighlightSelectModal(word, item.id, index);
                                                                }}
                                                                className={className}
                                                            >
                {word}
            </span>
                                                        );
                                                    })}
                                                </div>

                                                <div className="text-start">
                                                    <p className="dark:text-gray-300 flex flex-wrap">
                                                        {isWordMapReady ? (
                                                            item?.translation?.ayat_translation?.split(" ").map((word, index) => {
                                                                const highlightWord = highlighWordMapRef.current.get(`${surahDetails.surah?.id}_${item.id}_${word}`);
                                                                const className = `${highlightWord === word ? "bg-yellow-400 text-white font-bold" : ""} mx-1 cursor-pointer`;
                                                                return (
                                                                    <span
                                                                        key={`ayat_translation_word_${item.id}_${index}`}
                                                                        onClick={() => handleOpenCopyOrHighlightSelectModal(word, item.id, index)}
                                                                        className={className}
                                                                    >
                    {word}
                </span>
                                                                );
                                                            })
                                                        ) : (
                                                            ""
                                                        )}
                                                    </p>
                                                    {item?.translator_name ? (
                                                        <small className="text-gray-400">
                                                            translated by : <span
                                                            className="text-yellow-400">{item?.translator_name}</span>
                                                        </small>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                        )}
                    </div>
                )}
            </Header>
        </>
    );
}

export default AyatDetails;
