import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import { IAppContextState, AppContext } from "../../../App";

import { IBookmark } from "../interface/bookmark.interface";

import { BookmarkService } from "../service/BookmarkService";

import BookmarkSkeleton from "../skeleton/BookmarkSkeleton";
import { ELanguageCode } from "../../../shared/enum/language.enum";

function BookmarkDetails() {
  const { t } = useTranslation();
  const { collectionId } = useParams();

  const bookmarkService = new BookmarkService();

  const { bookmarkState, langCodeState } = useContext<IAppContextState | any>(AppContext);

  const [langCode] = langCodeState;
  const [bookmarks, setBookmarks] = bookmarkState;

  const [loading, setLoading] = useState<boolean>(false);

  const removeBookmark = async (id: number) => {
    try {
      await bookmarkService.removeBookmark(id);
      await loadAyats();
      toast.success(`${t("bookmark.messages.ayat.delete")}`);
    } catch (error: any) {
      toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
    }
  };

  const loadAyats = async () => {
    try {
      setLoading(true);
      const res = await bookmarkService.getBookmark();
      setBookmarks(res?.data ?? []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAyats();
  }, [collectionId]);

  return (
    <>
      <h1 className="border-b-2 border-yellow-400 border-opacity-10 text-center font-normal text-[2rem] py-8 dark:text-yellow-400">
        {t("bookmark.title")}
      </h1>

      {loading ? (
        <BookmarkSkeleton />
      ) : bookmarks?.length ? (
        bookmarks.map((item: IBookmark, key: number) => (
          <div
            id={`ayat_${item.id}`}
            key={`ayat_list_${key}`}
            className="border-b border-gray-300 border-dashed dark:border-gray-500 last:border-0"
          >
            <div className="lg:flex justify-between items-end mb-8 mt-6">
              <div className="w-[95%]">
                <p className="font-arabic flex justify-end items-end text-[1.5rem] mb-8"></p>
                <div className="text-start">
                  {langCode === ELanguageCode.ENGLISH ? (
                    <p className="dark:text-gray-300">{item?.ayat?.ayat_translation_english ?? ""}</p>
                  ) : (
                    <></>
                  )}
                  {langCode === ELanguageCode.BANGLA ? (
                    <p className="dark:text-gray-300">{item?.ayat?.ayat_translation_bengali ?? ""}</p>
                  ) : (
                    <></>
                  )}

                  {item?.ayat?.translator_name ? (
                    <small className="text-gray-400 mt-4 inline-block">
                      Translated by : <span className="text-yellow-400">{item?.ayat?.translator_name}</span>
                    </small>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <ul className="w-[5%] flex lg:block mb-4 lg:mb-0">
                <li className="lg:mb-3 me-2">
                  <button
                    onClick={() => removeBookmark(item.id)}
                    className="group bg-red-500 hover:bg-red-400 rounded-full w-[34px] h-[34px] shadow dark:shadow-yellow-400"
                  >
                    <i className="ri-delete-bin-line text-white text-lg"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        ))
      ) : (
        <p className="my-8 text-center dark:text-gray-300">{t("bookmark.notFound")}</p>
      )}
    </>
  );
}

export default BookmarkDetails;
