import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {AppContext} from "../../App";
import {AuthService} from "../auth/service/AuthService";
import {ToastService} from "../../shared/service/toastService";
import {IUser} from "../auth/interface/user.interface";
import ButtonSpinner from "../../shared/components/ButtonSpinner";
import {EFormErrorType} from "../../shared/enum/form.enum";
import ConfirmModal from "../../shared/components/ConfirmModal";
import {StorageService} from "../../shared/service/storageService";


type TProfileForm = {
    email: string;
    password: string;
};

const authService = new AuthService();

function ProfileDelete() {
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors, isValid},
    } = useForm<TProfileForm>({mode: "onChange"});

    const {t} = useTranslation();
    const {userState, tokenState} = useContext<any>(AppContext);

    const [user, setUser] = userState;
    const [token, setToken] = tokenState;
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [confirmModalData, setConfirmModalData] = useState<TProfileForm | null>(null);
    const storageService = new StorageService();
    const toastService = new ToastService();

    const handleDeleteProfile = async (data: TProfileForm) => {
        try {
            setIsSaving(true);
            const formData = buildFormData(data);
            await authService.deleteProfile(formData);
            setUser(null)
            setToken(null)
            storageService.clearUserToken();
            toastService.success(t("auth.message.profile.delete.success"));
        } catch (error: any) {
            toastService.error(error?.response?.data?.detail || t("auth.message.profile.delete.error"));
            console.error("Profile delete error: ", error);
        } finally {
            setIsSaving(false);
        }
    };

    const onSubmit = (data: TProfileForm) => {
        setConfirmModalData(data);
        setOpenConfirmModal(true);
    };

    const buildFormData = (data: TProfileForm) => {
        const formData = new FormData();
        data?.email && formData.append("email", data?.email);
        data?.password && formData.append("password", data?.password);
        return formData;
    };
    const handleConfirm = () => {
        if (confirmModalData) {
            handleDeleteProfile(confirmModalData);
        }
        setOpenConfirmModal(false);
    };

    const updateForm = (data: IUser) => {
        setValue("email", data.email);
    };

    useEffect(() => {
        if (user) {
            updateForm(user);
        }
    }, [user]);

    return (
        <>

            <ConfirmModal
                text={t("auth.message.profile.delete.confirm")}
                data={confirmModalData}
                clickAction={handleConfirm}
                state={[openConfirmModal, setOpenConfirmModal]}
            ></ConfirmModal>
            <div className="py-2">
                <div className="bg-white w-10/12 p-5 rounded-lg mx-auto border shadow-lg">
                    <h4 className="mb-8 font-bold text-2xl">{t("auth.title.delete_profile")}</h4>
                    <div className="flex ">
                        <div className="basis-2/3 lg:basis-1/2">

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="grid grid-cols-1 gap-4 mb-5">

                                    <div className="mb-5">
                                        <label htmlFor="profile_email">{t("auth.label.email")}</label>
                                        <div>
                                            <input
                                                disabled
                                                type="text"
                                                id="profile_email"
                                                className="bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700 dark:border-gray-600 border-gray-300 dark:focus:ring-yellow-400 dark:focus:border-yellow-400 focus:ring-yellow-400 focus:border-yellow-400"
                                                placeholder={t("auth.placeholder.email")}
                                                {...register("email")}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="password">{t("auth.label.password")}</label>
                                        <div className="mb-2">
                                            <input
                                                type="text"
                                                id="password"
                                                className="bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white dark:bg-gray-700 dark:border-gray-600 border-gray-300 dark:focus:ring-yellow-400 dark:focus:border-yellow-400 focus:ring-yellow-400 focus:border-yellow-400"
                                                placeholder={t("auth.placeholder.password")}
                                                {...register("password", {required: "Password is required"})}
                                            />
                                            {errors?.password?.type === EFormErrorType.REQUIRED ? (
                                                <span
                                                    className="text-red-600 text-xs">{t("auth.validation.password.required")}</span>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <button
                                    disabled={isSaving}
                                    type="submit"
                                    className="mt-5 inline cursor-pointer text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
                                >
                                    {isSaving ? <ButtonSpinner isLoading={isSaving}/> : <></>} {t("action.delete")}
                                </button>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProfileDelete;
