function BookmarkSkeleton() {
  const arr = Array.from({ length: 12 }, (_, index) => index + 1);
  return (
    <div className="mx-auto px-3 md:max-w-[600] xl:max-w-[1100px] 2xl:max-w-[1400px] animate-pulse">
      {arr?.map((key: number) => (
        <div
          key={`ayat_list_skeleton_${key}`}
          className="border-b border-gray-300 border-dashed dark:border-gray-500 last:border-0"
        >
          <div className="lg:flex justify-between mb-8 mt-6">
            <div className="w-[95%]">
              <div className="flex justify-end items-end mb-8">
                <div className="ms-2 h-6 bg-gray-200 rounded-full dark:bg-gray-500 w-20"></div>
              </div>
              <div className="text-start">
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-500 w-48 mb-2"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-500 w-32"></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default BookmarkSkeleton;
