import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IAppContextState, AppContext } from "../../../App";
import { BookmarkService } from "../service/BookmarkService";

const ShareButton = ({ item, handleShareOption }:any) => {
  const { langCodeState } = useContext<IAppContextState | any>(AppContext);
  const [langCode] = langCodeState;
  const [showOptions, setShowOptions] = useState(false);
  const bookmarkService = new BookmarkService();

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleShare = async (type:any) => {
    try {
        if(type==="PDF"){
            var res = await bookmarkService.exportBookmark(item.id,"pdf",langCode);
            if (res && res.doc_url) {
                window.open(res.doc_url, "_blank");
            } else {
                console.error("No doc_url found in response");
            }
        } else {
            var res = await bookmarkService.exportBookmark(item.id,"docx",langCode);
            if (res && res.doc_url) {
                window.open(res.doc_url, "_blank");
            } else {
                console.error("No doc_url found in response");
            }
        }
    } catch (error: any) {
        toast.error(error?.message || error?.response?.message || error?.data?.message || error?.response?.data?.message);
    }
    // Call the parent handler with the item ID and share type
    // handleShareOption(item.id, type); 
    // Close the dropdown
    setShowOptions(false); 
  };

  return (
    <div className="relative inline-block">
      {/* Share Button */}
      <button
        onClick={(e) => {
          e.preventDefault();
          toggleOptions();
        }}
        className="group mt-2 bg-red-500 hover:bg-red-400 rounded-full w-[34px] h-[34px] shadow dark:shadow-yellow-400"
      >
        <i className="ri-share-line text-white text-lg"></i>
      </button>

      {/* Dropdown Options */}
      {showOptions && (
        <div
          className="absolute top-12 right-0 bg-white dark:bg-gray-800 shadow-lg rounded-lg py-2"
          style={{ minWidth: "150px" }} // Adjust width here
        >
          <button
            onClick={(e) =>{
                e.preventDefault();
                handleShare("PDF")} 
            }
            className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 w-full text-left"
          >
            Share as PDF
          </button>
          <button
             onClick={(e) =>{
                e.preventDefault();
                handleShare("Word")} 
            }
            className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 w-full text-left"
          >
            Share as Word
          </button>
        </div>
      )}
    </div>
  );
};

export default ShareButton;
