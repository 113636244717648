import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from 'flowbite-react';
import { useTranslation } from 'react-i18next';

import { IAppContextState, AppContext } from '../../../App';

import { flowbiteCustomPaginationThemeConfig } from '../../../shared/config/flowbiteCustomThemeConfig';

import { EBookSort } from '../enum/books.enum';

import { IBook } from '../interface/book.interface';
import { IPaginationParams } from '../../../shared/interface/pagination.interface';

import { BookService } from '../service/BookService';

import { PAGE_SIZE_10 } from '../../../shared/constant/pagination.constant';

import BookCardSkeleton from '../skeleton/BookCardSkeleton';

function BookList() {
  const { t } = useTranslation();
  const { bookState } = useContext<IAppContextState | any>(AppContext);

  const [books, setBooks] = bookState;

  const bookService = new BookService();

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    getAllBooks({ page_size: PAGE_SIZE_10, page: page });
  };

  const handleSort = async (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === EBookSort.ALL) {
      getAllBooks({ page_size: PAGE_SIZE_10, page: currentPage });
    } else if (value === EBookSort.RECENT) {
      getAllRecentBooks({ page_size: PAGE_SIZE_10, page: currentPage });
    } else if (value === EBookSort.POPULAR) {
      getAllPopularBooks({ page_size: PAGE_SIZE_10, page: currentPage });
    }
  };

  const getAllBooks = async (params?: IPaginationParams) => {
    try {
      setLoading(true);
      const bookResponse = await bookService.getAll(params);
      setBooks(
        bookResponse?.data ?? {
          books: [],
          total_counts: 0,
        },
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getAllRecentBooks = async (params?: IPaginationParams) => {
    try {
      setLoading(true);
      const bookResponse = await bookService.getAllRecent(params);
      setBooks(
        bookResponse?.data ?? {
          books: [],
          total_counts: 0,
        },
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getAllPopularBooks = async (params?: IPaginationParams) => {
    try {
      setLoading(true);
      const bookResponse = await bookService.getAllPopular(params);
      setBooks(
        bookResponse?.data ?? {
          books: [],
          total_counts: 0,
        },
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const download = (url: string) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    getAllBooks({ page_size: PAGE_SIZE_10, page: currentPage });
  }, []);

  return (
    <>
      <h1 className='text-center font-normal text-[2rem] py-8 border-b-2 border-yellow-400 border-opacity-10 dark:text-yellow-400'>
        {t('book.title')}
      </h1>
      <div className='mt-6'>
        <div className='flex justify-between items-center'>
          {books?.total_counts ? (
            <div className='dark:text-white'>{t('book.filter.totalBook', { count: books?.total_counts })}</div>
          ) : (
            <></>
          )}
          <div>
            <select
              onChange={(e: ChangeEvent<HTMLSelectElement>) => handleSort(e)}
              id='book_sort_filter'
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'>
              <option selected value={EBookSort.ALL}>
                {t('book.filter.all')}
              </option>
              <option value={EBookSort.RECENT}>{t('book.filter.recent')}</option>
              <option value={EBookSort.POPULAR}>{t('book.filter.popular')}</option>
            </select>
          </div>
        </div>
      </div>
      {loading ? (
        <BookCardSkeleton />
      ) : (
        <div className='flex flex-wrap mt-12'>
          {books?.books?.length ? (
            books?.books.map((book: IBook, key: number) => (
              <div
                key={`${book.id}_${key}`}
                className='px-4 mb-6 2xl:w-[25%] lg:w-[33.33%] md:w-[50%] sm:w-[50%] w-[100%]'>
                <Link to={`/books/${book.id}`}>
                  <div className='bg-[#fbfbfb] rounded-lg flex items-center justify-between flex-col h-[100%] dark:bg-gray-600 dark:border-gray-500 border'>
                    <div className='h-64 overflow-hidden'>
                      <img src={book.image} alt={book.image} className='w-full rounded-lg' />
                    </div>
                    <div className='w-full p-4 relative'>
                      <p className='w-full font-semibold text-[1rem] text-gray-700 dark:text-gray-200'>{book.title}</p>
                      <p className='w-full truncate text-[0.8rem] text-blue-400 dark:text-gray-200'>
                        {book.short_description}
                      </p>

                      <button
                        onClick={() => download(book.pdf)}
                        className='absolute top-[-13px] right-[10px] bg-green-500 p-4 flex items-center justify-center h-5 w-5 rounded-full text-white hover:border-green-600 hover:bg-green-600'>
                        <i className='ri-download-2-line'></i>
                      </button>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <p className='text-center w-full'>{t('book.notFound')}</p>
          )}
        </div>
      )}
      {!loading && books.total_counts > PAGE_SIZE_10 && (
        <div className='flex overflow-x-auto sm:justify-center mb-6'>
          <Pagination
            theme={flowbiteCustomPaginationThemeConfig}
            layout='pagination'
            currentPage={currentPage}
            totalPages={books.total_counts / PAGE_SIZE_10}
            onPageChange={onPageChange}
            showIcons
          />
        </div>
      )}
    </>
  );
}

export default BookList;
