import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { AppContext, IAppContextState } from '../../../App';

import { BookService } from '../service/BookService';

import { IBook } from '../interface/book.interface';

function BookDetails() {
  const { isOpenSidebarState, isCollapsedSidebarState } = useContext<IAppContextState | any>(AppContext);

  const [isOpenSidebar] = isOpenSidebarState;
  const [isCollapsedSidebar] = isCollapsedSidebarState;

  const [loading, setLoading] = useState(false);
  const [bookDetails, setBookDetails] = useState<IBook | null>(null);

  const bookService = new BookService();

  const { id } = useParams();

  const getOne = async (id: number) => {
    try {
      setLoading(true);
      const bookResponse = await bookService.getOne(id);
      setBookDetails(bookResponse?.data ?? null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const download = (url: string) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (id) {
      getOne(Number(id));
    }
  }, [id]);

  return (
    <>
      <div className='py-11 px-8 sm:flex'>
        <div className='lg:w-[18rem]'>
          <img
            src={bookDetails?.image}
            alt='quran-bangla-cover'
            className='w-[100%] rounded-lg h-[100%] object-cover border'
          />
        </div>
        <div className='sm:ms-8'>
          <div>
            <h6 className='font-bold text-3xl mt-3 sm:mt-0 dark:text-yellow-400'>{bookDetails?.title}</h6>
            {bookDetails?.long_description && (
              <div className='flex flex-col'>
                <div className='pt-12'>
                  <h2 className='text-lg font-semibold mb-1 dark:text-gray-100'>Overview</h2>
                  <p className='mb-4 dark:text-gray-300'>{bookDetails.long_description}</p>
                </div>
                {bookDetails?.pdf && (
                  <div className='pt-4'>
                    <button
                      onClick={() => download(bookDetails.pdf)}
                      className='bg-green-500 px-6 py-2 flex items-center justify-center rounded-md text-white hover:border-green-600 hover:bg-green-600'>
                      Download
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BookDetails;
