function SearchListSkeleton() {
  const arr = Array.from({ length: 10 }, (_, index) => index + 1);
  return (
    <div>
      {arr?.map((item: number) => (
        <div key={`search_list_skeleton_${item}`} id={`search_list_skeleton_${item}`} className='border-b border-gray-300 border-dashed dark:border-gray-500 last:border-0 animate-pulse'>
          <div className='lg:flex justify-between mb-8 mt-6'>
            <div className='w-[95%]'>
              <div className='text-start'>
                <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-500 w-54 mb-2'></div>

                <div className='h-2 bg-gray-200 rounded-full dark:bg-gray-500 w-48'></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SearchListSkeleton;
