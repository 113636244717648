import { Outlet } from "react-router";

import BrandLogo from "../shared/components/BrandLogo";
import Navbar from "../shared/components/Navbar";
import Footer from "../shared/components/Footer";

function CommonLayout() {
  return (
    <div className="min-h-full h-full max-h-max">
      <div className="md:flex md:flex-col h-full md:justify-between overflow-auto">
        <Navbar>
          <BrandLogo />
        </Navbar>
        <div>
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default CommonLayout;
