import { FlowbiteDropdownTheme, FlowbiteTabsTheme, FlowbiteTooltipTheme, FlowbitePaginationTheme, FlowbiteFooterTheme } from 'flowbite-react';

export const flowbiteCustomDropdownThemeConfig: FlowbiteDropdownTheme = {
  arrowIcon: 'hidden',
  content: 'focus:outline-none',
  floating: {
    animation: 'transition-opacity',
    arrow: {
      base: '',
      placement: '',
      style: {
        dark: '',
        light: '',
        auto: '',
      },
    },
    base: 'z-10 w-fit rounded divide-y divide-gray-100 shadow focus:outline-none',
    content: 'py-1 text-sm text-gray-700 dark:text-gray-200',
    divider: ' h-px bg-gray-100 dark:bg-gray-600',
    header: 'block py-2 px-4 text-sm text-gray-700 dark:text-gray-200',
    hidden: 'invisible opacity-0',
    item: {
      container: '',
      base: 'flex items-center justify-start py-2 px-4 text-sm text-yellow-400 cursor-pointer w-full hover:bg-gray-100 focus:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 focus:outline-none dark:hover:text-white dark:focus:bg-gray-600 dark:focus:text-white',
      icon: 'mr-2 h-4 w-4',
    },
    style: {
      dark: 'bg-gray-900 text-white dark:bg-gray-700',
      light: 'border border-gray-200 bg-white text-gray-900',
      auto: 'border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-800 dark:text-white',
    },
    target: 'w-fit',
  },
  inlineWrapper: 'flex items-center',
};

export const flowbiteCustomTabThemeConfig: FlowbiteTabsTheme = {
  base: 'flex flex-col gap-2',
  tablist: {
    base: 'flex text-center',
    styles: {
      default: 'flex-wrap border-b border-gray-200 dark:border-gray-700',
      underline: 'flex-wrap -mb-px dark:border-gray-700',
      pills: 'flex-wrap font-medium text-sm text-gray-500 dark:text-gray-400 space-x-2',
      fullWidth: 'w-full text-sm font-medium divide-x divide-gray-200 shadow grid grid-flow-col dark:divide-gray-700 dark:text-gray-400 rounded-none',
    },
    tabitem: {
      base: 'flex items-center justify-center w-[33.33%] py-2 rounded-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500',
      styles: {
        default: {
          base: 'rounded-t-lg',
          active: {
            on: 'bg-gray-100 text-cyan-600 dark:bg-gray-800 dark:text-cyan-500',
            off: 'text-gray-500 hover:bg-gray-50 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800  dark:hover:text-gray-300',
          },
        },
        underline: {
          base: 'rounded-t-lg',
          active: {
            on: 'text-cyan-600 rounded-t-lg border-b-2 border-cyan-600 active dark:text-gray-700 dark:border-yellow-400',
            off: 'border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:border-gray-600',
          },
        },
        pills: {
          base: '',
          active: {
            on: 'rounded-lg bg-cyan-600 text-white',
            off: 'rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white',
          },
        },
        fullWidth: {
          base: 'ml-0 first:ml-0 w-full rounded-none flex',
          active: {
            on: 'p-4 text-gray-900 bg-gray-100 active dark:bg-gray-700 dark:text-white rounded-none',
            off: 'bg-white hover:text-gray-700 hover:bg-gray-50 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700 rounded-none',
          },
        },
      },
      icon: 'mr-2 h-5 w-5',
    },
  },
  tabitemcontainer: {
    base: '',
    styles: {
      default: '',
      underline: '',
      pills: '',
      fullWidth: '',
    },
  },
  tabpanel: 'p-0',
};

export const flowbiteCustomTooltipThemeConfig: FlowbiteTooltipTheme = {
  target: 'w-fit inline-block ms-3',
  animation: 'transition-opacity',
  arrow: {
    base: 'absolute z-10 h-2 w-2 rotate-45 transition-opacity',
    style: {
      dark: 'bg-gray-900 dark:bg-gray-400',
      light: 'bg-white',
      auto: 'bg-white dark:bg-gray-700',
    },
    placement: '-4px',
  },
  base: 'absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm',
  hidden: 'invisible opacity-0',
  style: {
    dark: 'bg-gray-900 text-white dark:text-gray-900 dark:bg-gray-400',
    light: 'border border-gray-200 bg-white text-gray-900',
    auto: 'border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white',
  },
  content: 'relative z-20',
};

export const flowbiteCustomPaginationThemeConfig: FlowbitePaginationTheme = {
  base: '',
  layout: {
    table: {
      base: 'text-sm text-gray-700 dark:text-gray-400',
      span: 'font-semibold text-gray-900 dark:text-white',
    },
  },
  pages: {
    base: 'xs:mt-0 mt-2 inline-flex items-center -space-x-px',
    showIcon: 'inline-flex',
    previous: {
      base: 'ml-0 rounded-l-lg border border-gray-300 bg-white py-2 px-3 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-yellow-600 enabled:dark:hover:text-white',
      icon: 'h-5 w-5',
    },
    next: {
      base: 'rounded-r-lg border border-gray-300 bg-white py-2 px-3 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-yellow-600 enabled:dark:hover:text-white',
      icon: 'h-5 w-5',
    },
    selector: {
      base: 'w-12 border border-gray-300 bg-white py-2 leading-tight text-gray-500 hover:bg-yellow-300 enabled:hover:text-white dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-yellow-600 enabled:dark:hover:text-white',
      active: 'bg-yellow-400 border-yellow-400 text-white hover:bg-yellow-600 dark:border-gray-700 dark:bg-yellow-600 dark:text-white',
      disabled: 'opacity-50 cursor-normal',
    },
  },
};

export const flowbiteCustomFooterThemeConfig: FlowbiteFooterTheme = {
  root: {
    base: 'w-full rounded-none bg-white shadow dark:bg-gray-800 md:flex md:items-center md:justify-between',
    container: 'w-full p-6',
    bgDark: 'bg-gray-800',
  },
  groupLink: {
    base: 'flex flex-wrap text-sm text-gray-500 dark:text-white',
    link: {
      base: 'me-4 last:mr-0 md:mr-6',
      href: 'hover:underline',
    },
    col: 'flex-col space-y-4',
  },
  icon: {
    base: 'text-gray-500 dark:hover:text-white',
    size: 'h-5 w-5',
  },
  title: {
    base: 'mb-6 text-sm font-semibold uppercase text-gray-500 dark:text-white',
  },
  divider: {
    base: 'my-6 w-full border-gray-200 dark:border-gray-700 sm:mx-auto lg:my-8',
  },
  copyright: {
    base: 'text-sm text-gray-500 dark:text-gray-400 sm:text-center',
    href: 'ml-1 hover:underline',
    span: 'ml-1',
  },
  brand: {
    base: 'mb-4 flex items-center sm:mb-0',
    img: 'mr-3 h-8',
    span: 'self-center whitespace-nowrap text-2xl font-semibold text-gray-800 dark:text-white',
  },
};
