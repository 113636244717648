import React from 'react';

const DonationSuccess = () => {
    return (
        <div className="donation-success">
            <h1>Thank You for Your Donation!</h1>
            <p>Your generosity makes a big difference.</p>
            <br/>
            <button className='bg-yellow-400 block text-white rounded-full py-2 px-3' onClick={() => window.location.href = '/'}>Go to Home</button>
        </div>
    );
};

export default DonationSuccess;
