import { useContext } from "react";
import { Navigate } from "react-router-dom";

import { AuthContext } from "./AuthProvider";

function NotAuthGuard({ children }: any) {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (!loading) {
    return !isAuthenticated ? children : <Navigate to="/" />;
  }
}

export default NotAuthGuard;
