export const googleTranslateElementInit = () => {
    if (window.google) {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "bn",
                includedLanguages: "en,bn,es,fr,de,it,ja,zh-CN,hi,ar,ru,pt,ko,tr,th,ms,vi,ur,fa",
                // layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
                autoDisplay: false,
            },
            "google_translate_element"
        );
    }
};