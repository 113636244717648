import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { EMAIL_REGEX, PASSWORD_REGEX } from "../../../shared/constant/regex.constant";

import { EFormErrorType } from "../../../shared/enum/form.enum";

import AuthFormContainer from "./AuthFormContainer";

import { AuthService } from "../service/AuthService";
import { ToastService } from "../../../shared/service/toastService";

type TRegisterForm = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password2: string;
};

const authService = new AuthService();
const toastService = new ToastService();

function Register() {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<TRegisterForm>({ mode: "onChange" });
  const { t } = useTranslation();

  const navigate = useNavigate();

  const password = watch("password");
  const password2 = watch("password2");

  const [isLoading, setIsLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const submit = async (data: TRegisterForm) => {
    try {
      const res = await authService.register(data);
      navigate("/auth/login");
      toastService.success(t("auth.message.register.success"));
    } catch (error: any) {
      setIsLoading(false);
      toastService.error(error?.message || error?.data?.message || t("auth.message.register.error"));
      console.error("Login: ", error);
    }
  };

  useEffect(() => {
    if (password !== password2) {
      clearErrors("password2");
      setError("password2", { type: "manual", message: "Password does not matched" });
    } else {
      clearErrors("password2");
    }
  }, [password2]);

  return (
    <AuthFormContainer>
      <form onSubmit={handleSubmit(submit)}>
        <h4 className="text-center font-bold text-2xl mb-5">{t("auth.title.register")}</h4>
        <div className="mb-5">
          <label htmlFor="register_first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            {t("auth.label.first_name")}
          </label>
          <input
            type="text"
            id="register_first_name"
            className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white ${
              errors?.first_name?.type === EFormErrorType.REQUIRED
                ? "dark:bg-red-700 dark:border-red-600 border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500 focus:ring-red-500 focus:border-red-500"
                : "dark:bg-gray-700 dark:border-gray-600 border-gray-300 dark:focus:ring-yellow-400 dark:focus:border-yellow-400 focus:ring-yellow-400 focus:border-yellow-400"
            }`}
            placeholder={t("auth.placeholder.first_name")}
            {...register("first_name", {
              required: true,
            })}
          />
          {errors?.first_name?.type === EFormErrorType.REQUIRED ? (
            <span className="text-red-600 text-xs">{t("auth.validation.first_name.required")}</span>
          ) : (
            <></>
          )}
        </div>
        <div className="mb-5">
          <label htmlFor="register_last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            {t("auth.label.last_name")}
          </label>
          <input
            type="text"
            id="register_last_name"
            className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white ${
              errors?.last_name?.type === EFormErrorType.REQUIRED
                ? "dark:bg-red-700 dark:border-red-600 border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500 focus:ring-red-500 focus:border-red-500"
                : "dark:bg-gray-700 dark:border-gray-600 border-gray-300 dark:focus:ring-yellow-400 dark:focus:border-yellow-400 focus:ring-yellow-400 focus:border-yellow-400"
            }`}
            placeholder={t("auth.placeholder.last_name")}
            {...register("last_name", {
              required: true,
            })}
          />
          {errors?.last_name?.type === EFormErrorType.REQUIRED ? (
            <span className="text-red-600 text-xs">{t("auth.validation.last_name.required")}</span>
          ) : (
            <></>
          )}
        </div>
        <div className="mb-5">
          <label htmlFor="login_email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            {t("auth.label.email")}
          </label>
          <input
            type="email"
            id="login_email"
            className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white ${
              errors?.email?.type === EFormErrorType.REQUIRED || errors?.email?.type === EFormErrorType.PATTERN
                ? "dark:bg-red-700 dark:border-red-600 border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500 focus:ring-red-500 focus:border-red-500"
                : "dark:bg-gray-700 dark:border-gray-600 border-gray-300 dark:focus:ring-yellow-400 dark:focus:border-yellow-400 focus:ring-yellow-400 focus:border-yellow-400"
            }`}
            placeholder={t("auth.placeholder.email")}
            {...register("email", {
              required: true,
              pattern: EMAIL_REGEX,
            })}
          />
          {errors?.email?.type === EFormErrorType.REQUIRED ? (
            <span className="text-red-600 text-xs">{t("auth.validation.email.required")}</span>
          ) : (
            <></>
          )}
          {errors?.email?.type === EFormErrorType.PATTERN ? (
            <span className="text-red-600 text-xs">{t("auth.validation.email.pattern")}</span>
          ) : (
            <></>
          )}
        </div>
        <div className="mb-5">
          <label htmlFor="register_password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            {t("auth.label.password")}
          </label>
          <div className="relative">
            <input
              type={isShowPassword ? "text" : "password"}
              id="register_password"
              placeholder={t("auth.placeholder.password")}
              className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white ${
                errors?.password?.type === EFormErrorType.REQUIRED || errors?.password?.type === EFormErrorType.PATTERN
                  ? "dark:bg-red-700 dark:border-red-600 border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500 focus:ring-red-500 focus:border-red-500"
                  : "dark:bg-gray-700 dark:border-gray-600 border-gray-300 dark:focus:ring-yellow-400 dark:focus:border-yellow-400 focus:ring-yellow-400 focus:border-yellow-400"
              }`}
              {...register("password", {
                required: true,
                pattern: PASSWORD_REGEX,
              })}
            />
            <div
              onClick={() => setIsShowPassword(!isShowPassword)}
              className="absolute top-2/4 right-[6px] -translate-y-2/4 cursor-pointer"
            >
              {isShowPassword ? <i className="ri-eye-line text-xl"></i> : <i className="ri-eye-off-line text-xl"></i>}
            </div>
          </div>
          {errors?.password?.type === EFormErrorType.REQUIRED ? (
            <span className="text-red-600 text-xs">{t("auth.validation.password.required")}</span>
          ) : (
            <></>
          )}
          {errors?.password?.type === EFormErrorType.PATTERN ? (
            <span className="text-red-600 text-xs">{t("auth.validation.password.pattern")}</span>
          ) : (
            <></>
          )}
        </div>
        <div className="mb-5">
          <label
            htmlFor="register_confirm_password"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {t("auth.label.confirm_password")}
          </label>
          <div className="relative">
            <input
              type={isShowConfirmPassword ? "text" : "password"}
              id="register_confirm_password"
              placeholder={t("auth.placeholder.confirm_password")}
              className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white ${
                errors?.password2?.type === EFormErrorType.REQUIRED ||
                errors?.password2?.type === EFormErrorType.PATTERN
                  ? "dark:bg-red-700 dark:border-red-600 border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500 focus:ring-red-500 focus:border-red-500"
                  : "dark:bg-gray-700 dark:border-gray-600 border-gray-300 dark:focus:ring-yellow-400 dark:focus:border-yellow-400 focus:ring-yellow-400 focus:border-yellow-400"
              }`}
              {...register("password2", {
                required: true,
                pattern: PASSWORD_REGEX,
              })}
            />
            <div
              onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
              className="absolute top-2/4 right-[6px] -translate-y-2/4 cursor-pointer"
            >
              {isShowConfirmPassword ? (
                <i className="ri-eye-line text-xl"></i>
              ) : (
                <i className="ri-eye-off-line text-xl"></i>
              )}
            </div>
          </div>
          {errors?.password2?.type === EFormErrorType.REQUIRED ? (
            <span className="text-red-600 text-xs">{t("auth.validation.password.required")}</span>
          ) : (
            <></>
          )}
          {errors?.password2?.type === EFormErrorType.PATTERN ? (
            <span className="text-red-600 text-xs">{t("auth.validation.password.pattern")}</span>
          ) : (
            <></>
          )}
        </div>
        <button
          disabled={!isValid || isLoading}
          type="submit"
          className="text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
        >
          {t("auth.action.register")}
        </button>
        <p className="text-center mt-2">
          {t("auth.label.already_have_an_account")}
          <Link to={"/auth/login"}>
            <a className="underline text-yellow-400 bold ms-2">{t("auth.action.login")}</a>
          </Link>
        </p>
      </form>
    </AuthFormContainer>
  );
}

export default Register;
