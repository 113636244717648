import { INote } from "../../modules/home/interface/note.interface";
import { IBookmark } from "../../modules/bookmark/interface/bookmark.interface";

import { ELanguageCode } from "../enum/language.enum";
import { IUser } from "../../modules/auth/interface/user.interface";

export enum EStorageKeys {
  ACCESS_TOKEN = "quran_rt_access_token",
  USER = "quran_rt_user",
  DARK_MODE = "quran_rt_dark_mode",
  LANG_CODE = "quran_rt_lang_code",
  BOOKMARK = "quran_rt_bookmark",
  BOOKMARKED_AYAT = "quran_rt_bookmarked_ayat",
  HIGHLIGHT = "quran_rt_highlight",
  IS_OPEN_SIDEBAR = "quran_rt_is_open_sidebar",
  IS_COLLAPSE_SIDEBAR = "quran_rt_is_collapse_sidebar",
  IS_READING_MODE = "quran_rt_is_reading_code",
  NOTE = "quran_rt_note",
}

export class StorageService {
  getAccessToken(): string | null {
    const token = localStorage.getItem(EStorageKeys.ACCESS_TOKEN);
    return token;
  }

  setAccessToken(token: string) {
    localStorage.setItem(EStorageKeys.ACCESS_TOKEN, token);
  }

  clearUserToken() {
    localStorage.removeItem(EStorageKeys.ACCESS_TOKEN);
    localStorage.removeItem(EStorageKeys.USER);
  }

  getUser(): string | null {
    const user = localStorage.getItem(EStorageKeys.USER);
    return user ? JSON.parse(user) : null;
  }

  setUser(user: IUser) {
    localStorage.setItem(EStorageKeys.USER, JSON.stringify(user));
  }

  getNotes(): INote[] {
    const notes = localStorage.getItem(EStorageKeys.NOTE);
    return notes ? JSON.parse(notes) : [];
  }
  setNotes(notes: INote[]) {
    localStorage.setItem(EStorageKeys.NOTE, JSON.stringify(notes));
  }

  getIsReadingMode() {
    return localStorage.getItem(EStorageKeys.IS_READING_MODE) === "true" ? true : false;
  }
  setIsReadingMode(isReadingMode: boolean) {
    localStorage.setItem(EStorageKeys.IS_READING_MODE, isReadingMode ? "true" : "false");
  }

  getIsCollapseSidebar() {
    return localStorage.getItem(EStorageKeys.IS_COLLAPSE_SIDEBAR) === "true" ? true : false;
  }
  setIsCollapseSidebar(isCollapseSidebar: boolean) {
    localStorage.setItem(EStorageKeys.IS_COLLAPSE_SIDEBAR, isCollapseSidebar ? "true" : "false");
  }

  getIsOpenSidebar() {
    return localStorage.getItem(EStorageKeys.IS_OPEN_SIDEBAR) === "true" ? true : false;
  }
  setIsOpenSidebar(isOpenSidebar: boolean) {
    localStorage.setItem(EStorageKeys.IS_OPEN_SIDEBAR, isOpenSidebar ? "true" : "false");
  }

  getIsDarkModeActivated() {
    return localStorage.getItem(EStorageKeys.DARK_MODE) === "true" ? true : false;
  }
  setIsDarkModeActivated(isActive: boolean) {
    localStorage.setItem(EStorageKeys.DARK_MODE, isActive ? "true" : "false");
  }

  getLanguageCode(): any {
    return localStorage.getItem(EStorageKeys.LANG_CODE);
  }
  setLanguageCode(langCode: ELanguageCode) {
    localStorage.setItem(EStorageKeys.LANG_CODE, langCode);
  }
}
