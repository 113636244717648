function SurahDetailsSkeleton() {
  const arr = Array.from({ length: 12 }, (_, index) => index + 1);
  return (
    <div className='mx-auto px-3 md:max-w-[600] xl:max-w-[1100px] 2xl:max-w-[1400px] animate-pulse'>
      <div className='flex justify-center pt-12 pb-16'>
        <div>
          <div className='h-6 bg-gray-200 rounded-full dark:bg-gray-500 w-48 mb-2'></div>
          <div className='flex justify-center'>
            <div className='h-2 bg-gray-200 rounded-full dark:bg-gray-500 w-20'></div>
          </div>
        </div>
      </div>
      {arr?.map((key: number) => (
        <div key={`ayat_list_skeleton_${key}`} className='border-b border-gray-300 border-dashed dark:border-gray-500 last:border-0'>
          <div className='lg:flex justify-between mb-8 mt-6'>
            <ul className='w-[5%] flex lg:block mb-4 lg:mb-0'>
              <li className='lg:mb-3 me-2 mt-1'>
                <div className='h-2 bg-gray-200 rounded-full dark:bg-gray-500 w-8'></div>
              </li>
              <li className='lg:mb-3 me-2'>
                <button className='group hover:bg-gold dark:hover:bg-yellow-400 rounded-full w-[34px] h-[34px] shadow dark:shadow-yellow-400'>
                  <i className='ri-play-fill group-hover:text-black text-gray-500 text-lg dark:text-gray-400'></i>
                </button>
              </li>
              <li className='lg:mb-3 me-2'>
                <button className='group hover:bg-gold dark:hover:bg-yellow-400 rounded-full w-[34px] h-[34px] shadow dark:shadow-yellow-400'>
                  <i className='ri-book-open-fill group-hover:text-black text-gray-500 text-lg dark:text-gray-400'></i>
                </button>
              </li>
            </ul>
            <div className='w-[95%]'>
              <div className='flex justify-end items-end mb-8'>
                <div className='ms-2 h-6 bg-gray-200 rounded-full dark:bg-gray-500 w-48'></div>
              </div>
              <div className='text-start'>
                <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-500 w-48 mb-2'></div>
                <div className='h-2 bg-gray-200 rounded-full dark:bg-gray-500 w-32'></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SurahDetailsSkeleton;
