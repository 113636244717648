function BookCardSkeleton() {
  const arr = Array.from({ length: 4 }, (_, index) => index + 1);
  return (
    <div className='flex flex-wrap mt-12'>
      {arr?.map((item: number) => (
        <div key={`book_list_skeleton_${item}`} className='px-4 mb-6 2xl:w-[25%] lg:w-[33.33%] md:w-[50%] sm:w-[50%] w-[100%]'>
          <div className='bg-[#fbfbfb] rounded-lg flex items-center justify-between flex-col h-[100%] dark:bg-gray-600 dark:border-gray-500'>
            <div className='h-64 overflow-hidden w-full'>
              <div role='status' className='flex h-full items-center justify-center aspect-[1/1.4] w-full bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700'>
                <svg className='w-10 h-10 text-gray-200 dark:text-gray-600' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 16 20'>
                  <path d='M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z' />
                  <path d='M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z' />
                </svg>
              </div>
            </div>
            <div role='status' className='space-y-2.5 animate-pulse w-full'>
              <div className='w-full p-4 relative'>
                <div className='h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-52 mb-2'></div>
                <div className='h-2 bg-gray-200 rounded-lg dark:bg-gray-700 w-48'></div>

                <div className='absolute top-[-13px] right-[10px] h-[2rem] w-[2rem] rounded-full ms-2 bg-gray-300 p-4 dark:bg-gray-600'></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default BookCardSkeleton;
