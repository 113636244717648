import axios from "axios";

import { ICreateHighlight, IHighlight } from "../interface/highlight.interface";
import { IHttpResponse } from "../../../shared/interface/http-response.interface";

import { UtilService } from "../../../shared/service/utilService";

import { BASE_URL } from "../../../environments/environment";

export class HighlightService {
  public utilService = new UtilService();

  async getHighlight(params?: any): Promise<IHttpResponse<IHighlight[]>> {
    const { data } = await axios.get(`${BASE_URL}/utility/highlight/`, {
      params,
      ...this.utilService.buildAuthorizationHeader(),
    });
    return data;
  }

  async createHighlight(payload: ICreateHighlight): Promise<IHighlight> {
    const { data } = await axios.post(
      `${BASE_URL}/utility/highlight/`,
      payload,
      this.utilService.buildAuthorizationHeader()
    );
    return data;
  }

  async removeHighlight(id: number): Promise<void> {
    const { data } = await axios.delete(
      `${BASE_URL}/utility/highlight/${id}`,
      this.utilService.buildAuthorizationHeader()
    );
    return data;
  }
}
