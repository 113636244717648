function SurahListSkeleton() {
  const arr = Array.from({ length: 12 }, (_, index) => index + 1);
  return (
    <div className='surah-container flex flex-wrap'>
      {arr?.map((item: number) => (
        <div key={`surah_list_skeleton_${item}`} className='px-2 xl:w-[33.33%] lg:w-[50%] md:w-[50%] w-[100%] h-[100%]'>
          <div className='mb-3 border border-gray-200 py-4 rounded-xl group hover:shadow-[0_9px_15px_0px_rgba(0,0,0,0.1)] hover:border-[rgba(202,138,4,0.25)] dark:border-gray-600 dark:hover:border-[rgba(202,138,4,0.25)] flex justify-center items-center cursor-pointer dark:bg-gray-800'>
            <div className='w-[20%]'>
              <div className='w-[90%] flex items-center justify-center bg-gray-300 dark:bg-gray-500 px-3 py-2 md:px-4 md:py-3 2xl:py-4 2xl:px-6 rounded-xl shadow group-hover:bg-yellow-400 group-hover:text-white dark:text-gray-400 dark:shadow-gray-600'>
                <svg className='w-10 h-10 text-gray-200 dark:text-gray-600' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 20 18'>
                  <path d='M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z' />
                </svg>
              </div>
            </div>
            <div className='w-[50%]'>
              <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-500 w-[30%] mb-2'></div>
              <div className='h-2 bg-gray-200 rounded-full dark:bg-gray-500 max-w-[20%] mb-2.5'></div>
            </div>
            <div className='w-[20%] text-end'>
              <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-500 w-full mb-2'></div>
              <div className='h-2 bg-gray-200 rounded-full dark:bg-gray-500 max-w-[30%] ml-auto mb-2.5'></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SurahListSkeleton;
