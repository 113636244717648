import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { Link } from "react-router-dom";

import { EShortCutSurah } from "../enum/short-cut-surah.enum";

function Header({ children }: any) {
  const { transcript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const { t } = useTranslation();

  const [searchInput, setSearchInput] = useState("");

  const navigate = useNavigate();

  if (!browserSupportsSpeechRecognition) {
    console.error("Browser not support microphone");
  }

  const handleKeyPressSearch = (event: any) => {
    if (event.key === "Enter") {
      navigate(`/search?search_text=${encodeURIComponent(searchInput)}`);
    }
  };

  const startSpeech = () => {
    SpeechRecognition.startListening();
  };

  const stopSpeech = () => {
    SpeechRecognition.startListening();
  };

  useEffect(() => {
    setSearchInput(() => transcript);
  }, [transcript]);

  return (
    <>
      <div className="banner">
        <p className="text-center text-[2.5rem] font-arabic font-bold">'بِسْمِ ٱللَّٰهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ</p>

        <div className="max-w-xl mx-auto my-12 px-3 md:px-0">
          <div className="mt-2 mb-4 px-2">
            <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
              {t("placeholder.searchBookmark")}
            </label>
            <div className="relative">
              <button
                type="button"
                className="cursor-pointer absolute inset-y-0 start-0 flex items-center ps-3 text-xl text-gray-600 hover:text-gray-700"
              >
                <i className="ri-search-line"></i>
              </button>
              <input
                onBlur={() => {
                  if (searchInput.length > 0) {
                    navigate(`/search?search_text=${encodeURIComponent(searchInput)}`);
                  }
                }}
                onKeyDown={(e: any) => handleKeyPressSearch(e)}
                type="text"
                id="header_search_input"
                className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-yellow-400 focus:border-yellow-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-500 dark:focus:border-yellow-500"
                placeholder={t("placeholder.searchAyat")}
                onChange={(e: any) => setSearchInput(e.target.value)}
                value={searchInput}
                required
              />

              <button
                onClick={() => (listening ? stopSpeech() : startSpeech())}
                type="button"
                className="absolute end-2.5 bottom-2.5 text-blue-600 font-bold text-xl"
              >
                {listening ? <i className="ri-mic-off-line"></i> : <i className="ri-mic-line"></i>}
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-center flex-col md:flex-row items-center">
          <div className="mb-3 md:mb-0">
            <Link
                to={`/surah/${EShortCutSurah.AL_MULK}`}
                className="md:me-4 px-8 py-2 rounded-full bg-white shadow text-gray-700 hover:text-black hover:shadow-lg dark:bg-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            >
              Al Mulk
            </Link>

          </div>
          <div className="mb-3 md:mb-0">

              <Link
                  to={`/surah/${EShortCutSurah.YASIN}`}
                  className="md:me-4 px-8 py-2 rounded-full bg-white shadow text-gray-700 hover:text-black hover:shadow-lg dark:bg-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              >
                Yasin
              </Link>

          </div>
          <div className="mb-3 md:mb-0">
              <Link className="md:me-4 px-8 py-2 rounded-full bg-white shadow text-gray-700 hover:text-black hover:shadow-lg dark:bg-gray-700 dark:text-gray-400 dark:hover:text-gray-200" to={`/surah/${EShortCutSurah.AL_KAHF}`}>Al Kahf</Link>
          </div>
          <div className="mb-3 md:mb-0">

              <Link className="px-8 py-2 rounded-full bg-white shadow text-gray-700 hover:text-black hover:shadow-lg dark:bg-gray-700 dark:text-gray-400 dark:hover:text-gray-200" to={`/surah/${EShortCutSurah.YUSUF}`}>Yusuf</Link>

          </div>
        </div>
      </div>
      <div className="px-1 md:px-3 lg:w-[90%] mx-auto">
        <div className="mb-8"></div>
        {children}
      </div>
    </>
  );
}

export default Header;
