// AuthContext.js
import { createContext, useContext, useEffect, useState } from "react";

import { AppContext } from "../../App";
import { StorageService } from "../service/storageService";

export const AuthContext = createContext<any>(null);

export const storageService = new StorageService();

export const AuthProvider = ({ children }: any) => {
  const { tokenState } = useContext<any>(AppContext);
  const [token, setToken] = tokenState;
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (token) {
      setIsAuthenticated(true);
      setLoading(false);
    } else if (!token) {
      const accessToken = storageService.getAccessToken();
      setIsAuthenticated(() => (accessToken ? true : false));
      setToken(accessToken);
      setLoading(false);
    }
  }, [token]);

  return <AuthContext.Provider value={{ isAuthenticated, loading }}>{children}</AuthContext.Provider>;
};
