"use client";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "flowbite-react";
import { toast } from "react-toastify";

import { AppContext, IAppContextState } from "../../App";

import { StorageService } from "../service/storageService";

interface ICopyOrHighlightSelectModalProps {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  closeAction: () => void;
  data?: any;
}

function CopyOrHighlightSelectModal({ state, closeAction, data }: ICopyOrHighlightSelectModalProps) {
  const { addOrRemoveHighlight, highlightState} = useContext<IAppContextState | any>(AppContext);
  const { t } = useTranslation();
  const [openModal,] = state;
  const [isExistHighlight, setIsExistHighlight] = useState(false);
  const [highlights] = highlightState;

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success(t("message.copy.success"));
  };

  useEffect(() => {
    const isExist = highlights.find((highlight: string) => highlight === data?.word);
    setIsExistHighlight(isExist ? true : false);
  }, [data?.word]);

  return (
    <>
      <Modal show={openModal} size="md" onClose={() => closeAction()} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 text-start text-lg font-normal text-gray-500 dark:text-gray-400">
              {t("modal.copy_or_highlight.title")}
            </h3>
            <div className="grid grid-rows-1">
              <div className="flex">
                <button
                  onClick={() => {
                    copyText(data?.word);
                    closeAction();
                  }}
                  type="button"
                  className="mx-2 text-white bg-yellow-400 border-yellow-400 hover:bg-yellow-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
                >
                  {t("action.copy")}
                </button>
                <button
                  onClick={(event) => {
                    addOrRemoveHighlight(data?.word,data?.ayatId);
                    closeAction();
                  }}
                  type="button"
                  className={`${
                    isExistHighlight
                      ? "bg-red-500 border-red-500 hover:bg-red-600 focus:ring-red-400 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                      : "bg-yellow-400 border-yellow-400 hover:bg-yellow-500 focus:ring-yellow-300 dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
                  } mx-2 text-white hover:text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center`}
                >
                  {isExistHighlight ? t("action.remove_highlight") : t("action.highlight")}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CopyOrHighlightSelectModal;
