import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import BrandLogo from "../../../shared/components/BrandLogo";
import AuthFormContainer from "./AuthFormContainer";

import { EFormErrorType } from "../../../shared/enum/form.enum";
import { EMAIL_REGEX } from "../../../shared/constant/regex.constant";

type TForgotPasswordForm = {
  email: string;
};

function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<TForgotPasswordForm>({ mode: "onChange" });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const submit = (data: TForgotPasswordForm) => {};

  return (
    <AuthFormContainer>
      <form onSubmit={handleSubmit(submit)}>
        <h4 className="text-center text-2xl font-bold">{t("auth.title.forgot_password")}</h4>
        <p className="text-center mb-4 text-xs">{t("auth.title.forgot_password.description")}</p>
        <div className="mb-5">
          <input
            type="email"
            id="register_email"
            className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white ${
              errors?.email?.type === EFormErrorType.REQUIRED || errors?.email?.type === EFormErrorType.PATTERN
                ? "dark:bg-red-700 dark:border-red-600 border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500 focus:ring-red-500 focus:border-red-500"
                : "dark:bg-gray-700 dark:border-gray-600 border-gray-300 dark:focus:ring-yellow-400 dark:focus:border-yellow-400 focus:ring-yellow-400 focus:border-yellow-400"
            }`}
            placeholder={t("auth.placeholder.email")}
            {...register("email", {
              required: true,
              pattern: EMAIL_REGEX,
            })}
          />
          {errors?.email?.type === EFormErrorType.REQUIRED ? (
            <span className="text-red-600 text-xs">{t("auth.validation.email.required")}</span>
          ) : (
            <></>
          )}
          {errors?.email?.type === EFormErrorType.PATTERN ? (
            <span className="text-red-600 text-xs">{t("auth.validation.email.pattern")}</span>
          ) : (
            <></>
          )}
        </div>
        <button
          disabled={!isValid || isLoading}
          type="submit"
          className="cursor-pointer text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
        >
          {t("auth.action.submit")}
        </button>
        <button
          onClick={() => navigate("/auth/login")}
          type="button"
          className="mt-3 border text-yellow-400 border-yellow-400 hover:bg-yellow-400 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
        >
          {t("auth.action.back_to_login")}
        </button>
      </form>
    </AuthFormContainer>
  );
}

export default ForgotPassword;
