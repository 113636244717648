function AuthFormContainer({ children }: any) {
  return (
    <div className="md:relative h-full">
      <div className="py-10">
        <div className="max-w-lg mx-auto shadow p-5 rounded-lg bg-white">{children}</div>
      </div>
    </div>
  );
}

export default AuthFormContainer;
