import { toast } from "react-toastify";

export class ToastService {
  success(msg: string) {
    toast.success(msg);
  }

  error(msg: string) {
    toast.error(msg);
  }

  warning(msg: string) {
    toast.warn(msg);
  }
}
