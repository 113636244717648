export function SurahListSidebarSkeleton() {
  const arr = Array.from({ length: 10 }, (_, index) => index + 1);
  return (
    <div role='status' className='max-w-sm animate-pulse'>
      {arr?.map((item: number) => (
        <div
          key={`surah_list_sidebar_skeleton_${item}`}
          className='flex justify-between items-center w-full border-b border-dashed py-2 px-2 dark:bg-gray-500 dark:border-gray-500 dark:hover:text-gold hover:text-gold dark:text-gray-400 cursor-pointer last:border-0'>
          <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-500 w-[15%]'></div>
          <div className='ms-2 h-2.5 bg-gray-200 rounded-full dark:bg-gray-500 w-[75%]'></div>
        </div>
      ))}
    </div>
  );
}

export function SurahListSidebarVerseSkeleton() {
  const arr = Array.from({ length: 10 }, (_, index) => index + 1);
  return (
    <div role='status' className='max-w-sm animate-pulse'>
      {arr?.map((item: number) => (
        <div
          key={`surah_list_sidebar_skeleton_${item}`}
          className='flex justify-center items-center w-full border-b border-dashed py-2 px-2 dark:bg-gray-500 dark:border-gray-500 dark:hover:text-gold hover:text-gold dark:text-gray-400 cursor-pointer last:border-0'>
          <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-500 w-full'></div>
        </div>
      ))}
    </div>
  );
}
