import CurrencyInputWithDropdown from "./CurrencyInput";

function Donate() {
  return (
    <>
      <h1 className='text-center font-normal text-[2rem] py-8 border-b-2 border-yellow-400 border-opacity-10 dark:text-yellow-300'>
        Donate
      </h1>
      <div className='my-6'>
        <div className='flex justify-center items-center py-12'>
          <img src='/images/donation.jpg' alt='' />
        </div>
        <h2 className='block text-center font-bold text-3xl dark:text-yellow-300'>Maa sha Allah!</h2>
        <p className='text-center mt-2 dark:text-white'>
          Congratulations on your impactful donation to the Islamic charity! Your generosity will create a <br />
          positive change in countless lives. Thank you for your kindness and support.{' '}
        </p>
        <CurrencyInputWithDropdown />
        {/* <div className='text-center mt-4'>
          <a
            href='mailto:quranrt.org@gmail.com'
            className='px-16 py-3 inline-block bg-yellow-300 cursor-pointer rounded-full text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 text-cyan-600 border-b-2 border-cyan-600 dark:text-gray-700 dark:border-yellow-400'>
            Donate Now
          </a>
        </div> */}
      </div>
    </>
  );
}

export default Donate;
