import { Navigate } from "react-router-dom";
import { useContext } from "react";

import { AuthContext } from "./AuthProvider";

const AuthGuard = ({ children }: any) => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (!loading) {
    return isAuthenticated ? children : <Navigate to="/auth/login" />;
  }
};

export default AuthGuard;
