import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    en: {
      translations: require("./en/translations.json"),
    },
    bn: {
      translations: require("./bn/translations.json"),
    },
    urdu: {
      translations: require("./urdu/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "bn", "urdu"];

export default i18n;
