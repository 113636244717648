import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { EFormErrorType } from "../../../shared/enum/form.enum";

import { EMAIL_REGEX, PASSWORD_REGEX } from "../../../shared/constant/regex.constant";

import AuthFormContainer from "./AuthFormContainer";

import { AppContext } from "../../../App";

import { AuthService } from "../service/AuthService";
import { ToastService } from "../../../shared/service/toastService";
import { StorageService } from "../../../shared/service/storageService";

import ButtonSpinner from "../../../shared/components/ButtonSpinner";

type TLoginForm = {
  email: string;
  password: string;
};

const toastService = new ToastService();
const authService = new AuthService();
const storageService = new StorageService();

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<TLoginForm>({ mode: "onChange" });
  const { t } = useTranslation();
  const { userState, tokenState } = useContext<any>(AppContext);
  const setToken = tokenState[1];
  const setUser = userState[1];
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const submit = async (data: TLoginForm) => {
    try {
      setIsLoading(true);
      const res = await authService.login(data);
      storageService.setAccessToken(res?.access_token);
      setToken(res?.access_token);
      setUser(res);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toastService.error(error?.message || error?.data?.message || t("auth.message.login.error"));
      console.error("Login: ", error);
    }
  };

  return (
    <AuthFormContainer>
      <form onSubmit={handleSubmit(submit)}>
        <h4 className="text-center font-bold text-2xl mb-5">{t("auth.title.login")}</h4>
        <div className="mb-5">
          <label htmlFor="register_email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            {t("auth.label.email")}
          </label>
          <input
            type="email"
            id="register_email"
            className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white ${
              errors?.email?.type === EFormErrorType.REQUIRED || errors?.email?.type === EFormErrorType.PATTERN
                ? "dark:bg-red-700 dark:border-red-600 border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500 focus:ring-red-500 focus:border-red-500"
                : "dark:bg-gray-700 dark:border-gray-600 border-gray-300 dark:focus:ring-yellow-400 dark:focus:border-yellow-400 focus:ring-yellow-400 focus:border-yellow-400"
            }`}
            placeholder={t("auth.placeholder.email")}
            {...register("email", {
              required: true,
              pattern: EMAIL_REGEX,
            })}
          />
          {errors?.email?.type === EFormErrorType.REQUIRED ? (
            <span className="text-red-600 text-xs">{t("auth.validation.email.required")}</span>
          ) : (
            <></>
          )}
          {errors?.email?.type === EFormErrorType.PATTERN ? (
            <span className="text-red-600 text-xs">{t("auth.validation.email.pattern")}</span>
          ) : (
            <></>
          )}
        </div>
        <div>
          <label htmlFor="register_password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            {t("auth.label.password")}
          </label>
          <div className="relative">
            <input
              type={isShowPassword ? "text" : "password"}
              id="register_password"
              placeholder={t("auth.placeholder.password")}
              className={`bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white ${
                errors?.password?.type === EFormErrorType.REQUIRED || errors?.password?.type === EFormErrorType.PATTERN
                  ? "dark:bg-red-700 dark:border-red-600 border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500 focus:ring-red-500 focus:border-red-500"
                  : "dark:bg-gray-700 dark:border-gray-600 border-gray-300 dark:focus:ring-yellow-400 dark:focus:border-yellow-400 focus:ring-yellow-400 focus:border-yellow-400"
              }`}
              {...register("password", {
                required: true,
                pattern: PASSWORD_REGEX,
              })}
            />
            <div
              onClick={() => setIsShowPassword(!isShowPassword)}
              className="absolute top-2/4 right-[6px] -translate-y-2/4 cursor-pointer"
            >
              {isShowPassword ? <i className="ri-eye-line text-xl"></i> : <i className="ri-eye-off-line text-xl"></i>}
            </div>
          </div>
          {errors?.email?.type === EFormErrorType.REQUIRED ? (
            <span className="text-red-600 text-xs block">{t("auth.validation.password.required")}</span>
          ) : (
            <></>
          )}
          {errors?.password?.type === EFormErrorType.PATTERN ? (
            <span className="text-red-600 text-xs block">{t("auth.validation.password.pattern")}</span>
          ) : (
            <></>
          )}
        </div>
        <div className="mb-5">
          <Link to={"/auth/forgot-password"}>
            <a className="text-sm text-yellow-400 underline block mt-2">{t("auth.action.forgot_password")}</a>
          </Link>
        </div>
        <button
          disabled={!isValid || isLoading}
          type="submit"
          className="cursor-pointer text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
        >
          <ButtonSpinner isLoading={isLoading} />
          {t("auth.action.login")}
        </button>
        <p className="text-center mt-2">
          {t("auth.label.dont_have_an_account")}
          <Link to={"/auth/register"}>
            <a className="underline text-yellow-400 bold ms-2">{t("auth.action.register")}</a>
          </Link>
        </p>
      </form>
    </AuthFormContainer>
  );
}
